import { render, staticRenderFns } from "./MenuFeaturesSection.vue?vue&type=template&id=20a16b3e&scoped=true"
import script from "./MenuFeaturesSection.vue?vue&type=script&lang=js"
export * from "./MenuFeaturesSection.vue?vue&type=script&lang=js"
import style0 from "./MenuFeaturesSection.vue?vue&type=style&index=0&id=20a16b3e&prod&lang=scss&scoped=true"
import style1 from "./MenuFeaturesSection.vue?vue&type=style&index=1&id=20a16b3e&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20a16b3e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FeaturedMenuNav: require('/opt/build/repo/components/menu/FeaturedMenuNav.vue').default,FeaturedMenuCategory: require('/opt/build/repo/components/menu/FeaturedMenuCategory.vue').default})
